import './search-job.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  const $twoToneButton = $('.preloadButton');
  const preloadCircle = function (id) {
    if ($twoToneButton.length < 1) {
      return;
    }
    this.vzhik = function () {
      $twoToneButton.text('Searching...');
      $twoToneButton.addClass('spinning');
      setTimeout(function () {
        $twoToneButton.text('Search now');
        $twoToneButton.removeClass('spinning');
      }, 2000);
    };
    return this;
  };
  $twoToneButton.on('click', function (e) {
    e.preventDefault();
    preloadCircle('rse').vzhik();
  });
});
