import './timeline.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  $('.timeline > div:first-child').addClass('visibleContent');
  // $('.timeline > div:first-child').addClass('pulseFirst');
  $(window).scroll(function () {
    $('.timeline > div').each(function () {
      const scrollTop = $(window).scrollTop(),
        elementOffset = $(this).offset().top,
        distance = (elementOffset - scrollTop),
        windowHeight = $(window).height(),
        breakPoint = windowHeight * 0.5,
        breakPointMin = windowHeight * 0.38,
        breakPointMax = windowHeight * 0.62;
      if (distance > breakPoint) {
        $(this).addClass('hideanimate');
      } if (distance < breakPoint) {
        $(this).removeClass('hideanimate');
        // $(this).addClass('pulse');
        $(this).addClass('visibleContent');
      } if (distance < breakPointMin) {
        // $(this).removeClass('pulse');
      } if (distance > breakPointMax) {
        // $(this).removeClass('pulse');
      }
    });
  });
});


const TimeLine = (function () {
  const __private = {
    section: document.querySelector('.timeline'),
    animate: 'pulse',
    line: window.innerHeight * 0.6,

    run() {
      const elems = this.section instanceof HTMLElement ? this.section.children : null;

      if (elems) elems[0].classList.add(this.animate);

      window.addEventListener('scroll', __private.listener, true);
    },

    listener() {
      const activeItem = __private.section instanceof HTMLElement
        ? __private.section.querySelector(`.${__private.animate}`)
        : null;
      const prevItem = activeItem instanceof HTMLElement
        ? activeItem.previousElementSibling
        : null;
      const nextItem = activeItem instanceof HTMLElement
        ? activeItem.nextElementSibling
        : null;

      if (nextItem instanceof HTMLElement && nextItem.getBoundingClientRect().top <= __private.line) __private.switch(nextItem);

      if (prevItem instanceof HTMLElement && prevItem.getBoundingClientRect().top >= __private.line) __private.switch(prevItem);
    },

    switch(actine) {
      const elems = this.section instanceof HTMLElement ? this.section.children : null;

      if (elems) {
        for (let i = 0; i < elems.length; i++) {
          elems[i].classList.remove(this.animate);
        }
      }

      actine.classList.add(this.animate);
    }
  };

  return {
    init() {
      __private.run();
    }
  };
})();

TimeLine.init();