import './about-sales.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  const sliderSelector = '.review-slider.swiper-container';

  function initRevSlider(slider) {
    const initClass = 'init';
    let revSlider;
    const counter = slider instanceof HTMLElement && slider.getElementsByClassName('swiper-slide').length > 1
      ? slider.getElementsByClassName('swiper-slide').length
      : 0;

    try {
      if (counter > 0 && !slider.classList.contains(initClass)) {
        revSlider = new Swiper(slider, {
          spaceBetween: 50,
          autoHeight: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        slider.classList.add(initClass);
      }
    } catch (error) {
      console.warn(error);
    }

    return revSlider;
  }
  const tabs = {
    $nav: $('.about-sales__nav-item'),
    classes: {
      navActive: 'about-sales__nav-item--active',
      tabActive: 'as-tab--active',
    },
    getNavItem: function (id) {
      return $(`.about-sales__nav-item[data-tab-name='${id}']`);
    },
    getTabItem: function (id) {
      return $(`.as-tab[data-tab-name='${id}']`);
    },
    change: function (id) {
      $('.' + tabs.classes.tabActive).removeClass(tabs.classes.tabActive);
      $('.' + tabs.classes.navActive).removeClass(tabs.classes.navActive);
      tabs.getNavItem(id).addClass(tabs.classes.navActive);
      tabs.getTabItem(id).addClass(tabs.classes.tabActive);

      const slider = tabs.getTabItem(id)[0] instanceof HTMLElement && tabs.getTabItem(id)[0].querySelector(sliderSelector)
        ? tabs.getTabItem(id)[0].querySelector(sliderSelector) : null;

      initRevSlider(slider);
    }
  };

  tabs.$nav.click(function () {
    tabs.change($(this).data('tab-name'));
  });

  const firstSlider = document.querySelector('.as-tab--active ' + sliderSelector);

  initRevSlider(firstSlider);
});
