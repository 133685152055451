import './a-testimonials.component.scss'

import $ from 'jquery'

import 'swiper/css'
import Swiper from 'swiper'

$(document).ready(function () {
  new Swiper('.a-testimonials__slider', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
      600: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      1320: {
        slidesPerView: 2
      }
    }
  })
})
