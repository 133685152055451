import './popup.component.scss'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const popupOpenNodes = document.querySelectorAll('[data-popup-link]')
const initNotDelegated = () => {
  popupOpenNodes.forEach(link => {
    const isNotDelegated = !(link.closest('[data-popup-delegation]'))
    if (isNotDelegated) {
      link.addEventListener('click', event => {
        event.preventDefault()
  
        const popupId = link.dataset.popupLink
        const popup = document.querySelector(`[data-popup-id='${popupId}']`)
  
        if (popup) {
          popup.classList.add('opened')
  
          disableBodyScroll(popup.querySelector('.uni-popup__body'))
        }
      })
    }
  })
}
const initDelegated = () => {
  const delegatedZones = document.querySelectorAll('[data-popup-delegation]')
  delegatedZones.forEach(zone => {
    zone.addEventListener('click', event => {
      const openLink = event.target.closest('[data-popup-link]')
      if (openLink) {
        event.preventDefault()
        const popupId = openLink.dataset.popupLink
        const popup = document.querySelector(`[data-popup-id='${popupId}']`)
  
        if (popup) {
          popup.classList.add('opened')
  
          disableBodyScroll(popup.querySelector('.uni-popup__body'))
        }
      }
    })
  })
}

initNotDelegated()
initDelegated()

const popupNodes = document.querySelectorAll('.uni-popup')
popupNodes.forEach(popup => {
  const popupCloseBtns = popup.querySelectorAll('.uni-popup__close-btn, .uni-popup__overlay')
  popupCloseBtns.forEach(btn => {
    btn.addEventListener('click', event => {
      event.preventDefault()

      popup.classList.remove('opened')

      enableBodyScroll(popup.querySelector('.uni-popup__body'))
    })
  })
})