import './form.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  $('.form__file-resume input[type=file]').change(function () {
    const filename = $(this).val().replace(/.*\\/, '');
    const pref = window.innerWidth > 768 ? 'File uploaded: ' : '';

    if (filename) {
      $('#filename').text(pref + filename);
    } else {
      $('#filename').text('ATTACH RESUME')
    }
  })
})