import './results.component.scss'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import Swiper, { Navigation, Thumbs } from 'swiper'
Swiper.use([Navigation, Thumbs])

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import $ from 'jquery'

$(document).ready(function () {
  let slider
  $(document).on('click', '.result-case__link', function (e) {
    e.preventDefault()
    let template = ''
    $('.results__list .result-case').each(function (index) {
      $(this).data('index', index)
      template += `<div class="swiper-slide"><div class="result-case result-case--big">${$(
        this
      ).html()}</div></div>`
    })
    $('.results-popup .swiper-wrapper').html(template)
    $('.results-popup .swiper-wrapper .result-case .result-case__link').remove()
    const num = $(this).parents('.result-case').data('index')
    $('.results-popup').addClass('results-popup--show')
    slider = new Swiper('.results-popup .swiper-container', {
      slidesPerView: 1,
      loop: true,
      autoHeight: true,
      spaceBetween: 50,
      navigation: {
        prevEl: '.results-popup .results-popup__prev',
        nextEl: '.results-popup .results-popup__next'
      }
    })
    slider.slideTo(num + 1, 0)
    disableBodyScroll(
      $('.results-popup').find('.results-popup__content')[0]
    )
    $('body').addClass('blured')
  })
  $('.results-popup__close').click(function () {
    clearAllBodyScrollLocks()
    $('.results-popup').removeClass('results-popup--show')
    $('.results-popup .swiper-wrapper').html('')
    slider.destroy()
    $('body').removeClass('blured')
  })

  if ($('.results__form').length > 0) {
    $(window).scroll(function () {
      if ($(this).width() > 1024) {
        $('.results__form input:focus').blur()
      }
    })
  }
})
