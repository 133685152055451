(function () {
  function orientationChangeHandler() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }

  function subscribe(match, handler) {
    if (match.addListener) {
      match.addListener(handler)
    } else {
      match.addEventListener('change', handler)
    }
  }

  function matchMedia(media) {
    const match = window.matchMedia(media)
    return function (handler) {
      return subscribe(match, handler)
    }
  }
  matchMedia('(orientation: portrait)')(orientationChangeHandler)
})()