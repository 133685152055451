const refs = document.querySelectorAll('.article__content a:not([class])')

function formatUrl(url) {
  const doubleSlash = url.split('//')

  const formatted = doubleSlash
    .map(
      (str) =>
        str
          .replace(/(?<after>:)/giu, '$1<wbr>')
          .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
          .replace(/(?<equals>=)/giu, '<wbr>$1<wbr>')
          .replace(/(?<ampersand>&amp)/giu, '<wbr>&<wbr>')
    )
    .join('//<wbr>')

  return formatted
}

refs.forEach((url) => {
  const formatted = formatUrl(url.innerHTML)

  return (url.innerHTML = formatted)
})