const dynamicVars = () => {
  const doc = document.documentElement

  // const setHeaderHeight = () => {
  //   doc.style.setProperty('--header-height', `${header.clientHeight}px`)
  // }
  const setAppHeight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  const setVars = () => {
    setAppHeight()
  }
  setVars()
  window.addEventListener('resize', setVars)
}
dynamicVars()