import './gallery.component.scss'

import $ from 'jquery'

import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

/*
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import Swiper, { Navigation, Thumbs } from 'swiper'
Swiper.use([Navigation, Thumbs])
*/

$(document).ready(function () {
  const galleryEl = document.querySelector('.gallery__slider')

  if (galleryEl) {
    new Swiper(galleryEl, {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.gallery__slider-next',
        prevEl: '.gallery__slider-prev',
      },
    })
  }
  /*
  const galleryTopEl = document.querySelector('.gallery-top')
  const galleryThumbEl = document.querySelector('.gallery-thumbs')

  if (galleryTopEl && galleryThumbEl) {
    const galleryThumbsSlider = new Swiper(galleryThumbEl, {
      slidesPerView: 4,
      spaceBetween: 4,
      navigation: {
        prevEl: galleryThumbEl.querySelector('.gallery-thumbs__arrow_prev'),
        nextEl: galleryThumbEl.querySelector('.gallery-thumbs__arrow_next')
      }
    })
    new Swiper(galleryTopEl, {
      slidesPerView: 1,
      loop: false,
      autoHeight: true,
      navigation: {
        prevEl: galleryTopEl.querySelector('.gallery-top__arrow_prev'),
        nextEl: galleryTopEl.querySelector('.gallery-top__arrow_next')
      },
      thumbs: galleryThumbsSlider
    })
  }*/
  $('.gallery-top').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    asNavFor: '.gallery-thumbs',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          nextArrow: '<i class="slick-next icon-chevron-right"></i>',
          prevArrow: '<i class="slick-prev icon-chevron-left"></i>',
          asNavFor: false
        }
      }
    ]
  })
  $('.gallery-thumbs').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.gallery-top',
    infinite: false,
    arrows: true,
    nextArrow: '<i class="slick-next icon-arrow-right"></i>',
    prevArrow: '<i class="slick-prev icon-arrow-left"></i>',
    centerPadding: '15px',
    focusOnSelect: true
  })
})