import SmoothScroll from 'smooth-scroll'


const scrollInstance = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  ignore: '[href="#"]',
  offset() {
    const headerHeight = document.querySelector('.header').offsetHeight
    return headerHeight
  }
})
try {
  if (window.location.hash) {
    scrollInstance.animateScroll(document.querySelector(window.location.hash))
  }
} catch (err) { }