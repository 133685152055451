import './header.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  $(document).on('mouseup', function (e) {
    const menu = $('.header__sub-menu');
    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
      $('.header__menu-item--sub').removeClass('transformed');
    }
  });

  if ($(window).width() > 960) {
    $('.header__menu-item--sub > a').on('touchstart', function (e) {
      if (!$(this).parent().hasClass('transformed')) {
        $('.header__menu-item--sub').removeClass('transformed');
        $(this).parent().addClass('transformed');
      } else {
        return true;
      }

      e.preventDefault();
    });

  }
  const header = $('.header'), body = $('body');
  function bodyPaddingTop() {
    body.css('padding-top', header.height());
  }
  $(window).resize(bodyPaddingTop).trigger('resize');
  $('.header__menu-button').click(function () {
    $('.header').toggleClass('header--menu-active');
    $('body').toggleClass('disable-scroll');
  });
  $(window).scroll(function () {
    const scroll = $(window).scrollTop();
    if (scroll > 100) {
      header.addClass('is-sticky');
    } else {
      header.removeClass('is-sticky');
    }
  });
  $(window).resize(function () {
    $('body').removeClass('disable-scroll');
    $('header').removeClass('header--menu-active');
  })
})