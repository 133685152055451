const videoBlocks = document.querySelectorAll('.m-video')
videoBlocks.forEach(item => {
  const iframe = item.querySelector('iframe.m-video__video')
  const video = item.querySelector('video.m-video__video')
  const btn = item.querySelector('.m-video__thumb')

  const activate = () => {
    let src = iframe.dataset.src
    if (!src.includes('wistia')) {
      src += '&autoplay=1&playsinline=1'
    } else {
      src += '?autoplay=1?playsinline=1'
    }
    delete iframe.dataset.src
    iframe.src = src
    btn.classList.add('hidden')
    iframe.click()
    item.removeEventListener('click', activate)
  }

  let inited = false
  const toggle = () => {
    const source = video.querySelector('source')
    const srcTarget = (video.getAttribute('data-src') ? video : (source.getAttribute('data-src')) ? source : null)

    if (!inited) {
      item.classList.add('init')
      inited = true
      video.setAttribute('playsinline', '')
      video.src = srcTarget.getAttribute('data-src')
      srcTarget.removeAttribute('data-src')

      video.play()
    }

    if (!item.classList.contains('play')) {
      item.classList.add('play')

      video.play()
    } else {
      item.classList.remove('play')

      video.pause()
    }
  }

  if (iframe && btn) {
    btn.addEventListener('click', activate)
  } else if (video && btn) {
    btn.addEventListener('click', toggle)
  }
})