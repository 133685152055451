import './testimonials.component.scss'

import $ from 'jquery'

import 'swiper/css'
import 'swiper/css/pagination'
import Swiper, { Pagination } from 'swiper'
Swiper.use([Pagination])

const Testimonials = (function () {
  const __private = {
    itemClass: 't-item',
    bgClass: 't-item__bg',
    tagClass: 't-item__tag',
    nameClass: 't-item__name',
    textClass: 't-item__text',
    linkClass: 't-item__link',

    win: null,
    winId: '__js-testimonial-full',
    winMainClass: 'testi-win',
    winOpenClass: 'open',
    winCloseClass: 'close',

    dataset: {
      text: 'fullText'
    },

    listener() {
      this.createWin();

      document.addEventListener('click', function (event) {
        event = event || window.event;
        const target = event.target;

        if (target instanceof HTMLElement) {

          if (target === __private.win) __private.win.classList.remove(__private.winOpenClass);

          if (target.classList.contains(__private.linkClass)) {
            const item = __private.searchParentByClassName(target, __private.itemClass);

            if (item) __private.winTemptate(item);
          }

          if (target.classList.contains(__private.winCloseClass)) {
            const parent = __private.searchParentByClassName(target, __private.winMainClass);

            if (parent === __private.win) __private.winClear();
          }
        }
      });
    },

    createWin() {
      const win = document.createElement('div');

      win.id = this.winId;
      win.classList.add(this.winMainClass);

      document.body.appendChild(win);

      this.win = win;
    },

    winTemptate(item) {
      let bg;
      let tag;
      let name;
      let text;
      let tpl = null;

      if (item instanceof HTMLElement) {
        bg = item.querySelector(`.${this.bgClass}`) instanceof HTMLElement
          ? item.querySelector(`.${this.bgClass}`).style.backgroundImage
          : null;

        tag = item.querySelector(`.${this.tagClass}`) instanceof HTMLElement
          ? item.querySelector(`.${this.tagClass}`).textContent
          : null;

        name = item.querySelector(`.${this.nameClass}`) instanceof HTMLElement
          ? item.querySelector(`.${this.nameClass}`).textContent
          : null;

        text = item.querySelector(`.${this.textClass}`) instanceof HTMLElement
          ? item.querySelector(`.${this.textClass}`).dataset[this.dataset.text]
          : null;

        tpl = `
                    <div class='${this.winMainClass}__wrapper'>
                        <div class='${this.winMainClass}__frame'>
                            <div class='${this.winMainClass}__close ${this.winCloseClass}'></div>

                            <div class='${this.winMainClass}__wrap'>
                                <div class='${this.tagClass} '>${tag || ''}</div>
                                <div class='${this.nameClass}'>${name || ''}</div>
                                <div class='${this.textClass}'>${text || ''}</div>
                            </div>
                        </div>
                    </div>
                `;
      }

      if (this.win instanceof HTMLElement && tpl !== null) {
        const html = document.querySelector('html');

        this.win.innerHTML = tpl;
        this.win.style.backgroundImage = bg;
        this.win.classList.add(this.winOpenClass);

        html.style.height = '100%';
        html.style.overflow = 'hidden';
      }
    },

    winClear() {
      if (this.win instanceof HTMLElement) {
        const html = document.querySelector('html');

        this.win.classList.remove(this.winOpenClass);
        this.win.style.backgroundImage = '';
        this.win.innerHTML = '';

        html.style.height = '';
        html.style.overflow = '';
      }
    },

    searchParentByClassName(el, className) {
      if (el !== null && el instanceof HTMLElement && el !== undefined && el !== document.body) {
        if (el.classList.contains(className)) return el;
        else return this.searchParentByClassName(el.parentElement, className);
      } else return false;
    },

    fixTextLength(selector) {
      const textEmels = document.querySelectorAll(`${selector} .${this.textClass}`);

      for (let i = 0; i < textEmels.length; i++) {
        const element = textEmels[i];

        if (element instanceof HTMLElement) {
          const text = element.textContent;

          element.dataset[this.dataset.text] = text;

          if (text.length > 230) element.textContent = text.slice(0, 230) + '...';
        }
      }
    }
  };

  return {
    init(selector) {
      __private.fixTextLength(selector);
      __private.listener();
    }
  };
})();


$(document).ready(function () {
  const dynamicBullets = document.querySelectorAll('.testimonials__slider .swiper-slide').length > 7

  new Swiper('.testimonials__slider', {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 5000
    },
    autoHeight: false,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets,
      hide: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      }
    }
  });
  if ($(window).width() > 768) {
    if ($('.testimonials__slider .t-item').length === 2) {
      $('.swiper-wrapper').addClass('disabled');
      $('.swiper-pagination').addClass('disabled');
    }
  }
  if ($(window).width() < 768) {
    if ($('.testimonials__slider .t-item').length === 1) {
      $('.swiper-wrapper').addClass('disabled');
      $('.swiper-pagination').addClass('disabled');
    }
  }

  Testimonials.init('.testimonials__slider');
});