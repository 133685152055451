import './partners-animated.component.scss'

import $ from 'jquery'

$(document).ready(function () {
  class Logos {
    constructor($block) {
      this.offset = 0
      this.images = []
      this.$container = $block
      this.$imagesContainer = this.$container.find('.partners-animated__images')
      this.$itemsContainer = this.$container.find('.partners-animated__items')
      this.elements = parseInt(this.$container.data('elements-count'), 10)
      this.elements = this.elements ? this.elements : 9
      this.interval = parseInt(this.$container.data('interval'), 10)
      this.interval = this.interval > 2 ? this.interval : 2
    }
    init() {
      const self = this

      this.$imagesContainer.find('img').each(function () {
        self.images.push($(this).attr('nitro-lazy-src') || $(this).attr('src'))
      })

      for (let i = 0; i < this.elements; i++) {
        this.$itemsContainer.append(
          '<div class="partners-animated__item"></div>'
        )
      }

      if (navigator.userAgent.match(/iPhone|iPad|iPod/i))
        $('.partners-animated__item').css('transform', 'none')

      this.loop()
    }
    update() {
      const self = this
      let timeout = 0
      const delay = 100 // 500
      const of = self.offset
      for (let i = 0; i < self.elements; i++) {
        setTimeout(function () {
          const item = self.$itemsContainer
            .find('.partners-animated__item')
            .eq(i)
          item.removeClass('partners-animated__item--active')
          if (self.images.length - 1 >= i + of) {
            setTimeout(function () {
              item.html(`<img src="${self.images[i + of]}">`)
              item.addClass('partners-animated__item--active')
            }, 500) // 500
          } else {
            item.html('')
          }
        }, timeout)
        timeout += delay
      }
      setTimeout(function () {
        self.offset += self.elements
        if (self.offset >= self.images.length) {
          self.offset = 0
        }
      }, self.elements * delay)
    }
    loop() {
      const self = this
      self.update()
      if (self.images.length > self.elements) {
        setInterval(function () {
          self.update()
        }, self.interval * 1000)
      }
    }
  }
  const animatedLogos = new Logos($('.partners-animated'))
  animatedLogos.init()
})  