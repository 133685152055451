import './success-stories.component.scss'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import Swiper, { Pagination, Autoplay } from 'swiper'
Swiper.use([ Pagination, Autoplay ])

const storiesMainEl = document.querySelector('.stories-slider.swiper')
if (storiesMainEl) {
  const mainConfig = {
    spaceBetween: 10,
    autoHeight: true,
    pagination: {
      el: storiesMainEl.querySelector('.stories-slider__pagination'),
      clickable: true,
      dynamicBullets: true,
    },
    autoplay: {
      delay: 8000
    }
  }

  let popupSlider
  const popupSliderEl = document.querySelector('.stories-popup')
  if (popupSliderEl) {
    const popupConfig = {
      spaceBetween: 10,
      autoHeight: true,
      pagination: {
        el: popupSliderEl.querySelector('.stories-popup__pagination'),
        clickable: true,
        dynamicBullets: true
      },
      autoplay: {
        delay: 8000
      }
    }
    
    popupSlider = new Swiper(popupSliderEl, popupConfig)
  }

  const mainSlider = new Swiper(storiesMainEl, mainConfig)

  if (popupSliderEl) {
    popupSlider.on('slideChange', function () {
      mainSlider.slideTo(popupSlider.activeIndex)
    })
    mainSlider.on('slideChange', function () {
      popupSlider.slideTo(mainSlider.activeIndex)
    })
  }
}