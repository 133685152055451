import './hero-video.component.scss'

const AutoPlay = (function () {
  const __private = {
    isAndroid: navigator.userAgent.match(/Android/i) !== null ? true : false,

    run() {
      document.addEventListener('click', __private.play)

      this.play()
    },

    play() {
      const video = document.querySelector('.hero-video video')

      if (video instanceof HTMLVideoElement && __private.isAndroid === true) video.play()

      document.removeEventListener('click', __private.play)
    }
  }

  return {
    init() {
      __private.run()
    }
  }
})()

AutoPlay.init()