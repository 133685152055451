import './locations.component.scss'

const initMap = () => {
  let MAP;
  const infoWin = new google.maps.InfoWindow({});
  const container = document.getElementById('map');
  const coordinates = Array.isArray(window.__coordinates) ? window.__coordinates : null;
  const mainMarker = typeof window.__main_marker === 'string' ? window.__main_marker : null;
  const defMarker = typeof window.__def_marker === 'string' ? window.__def_marker : null;
  const defCenter = { lat: 41.92751, lng: -88.742559 };
  const defZoom = 5;
  const midZoom = 7;
  const bigZoom = 15;
  const lightGreyStyles = [
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e9e9e9'
        },
        {
          'lightness': 17
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        },
        {
          'lightness': 20
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 17
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 29
        },
        {
          'weight': 0.2
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 18
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'lightness': 16
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        },
        {
          'lightness': 21
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dedede'
        },
        {
          'lightness': 21
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'visibility': 'on'
        },
        {
          'color': '#ffffff'
        },
        {
          'lightness': 16
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'saturation': 36
        },
        {
          'color': '#333333'
        },
        {
          'lightness': 40
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f2f2f2'
        },
        {
          'lightness': 19
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#fefefe'
        },
        {
          'lightness': 20
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'color': '#fefefe'
        },
        {
          'lightness': 17
        },
        {
          'weight': 1.2
        }
      ]
    },
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] }
  ];
  const mapProp = {
    center: defCenter,
    zoom: defZoom,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.LARGE,
      position: google.maps.ControlPosition.LEFT_CENTER
    },
    disableDefaultUI: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    panControl: false,
    overviewMapControl: false,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: lightGreyStyles
  };

  const setSingleMarker = () => {
    const coord = {
      lat: coordinates[0].lat,
      lng: coordinates[0].lng
    };
    const props = {
      center: coord,
      position: coord,
      animation: google.maps.Animation.DROP
    };
    const content = coordinates[0].name || '';

    if (mainMarker !== null) props.icon = mainMarker;
    else if (defMarker !== null) props.icon = defMarker;

    const marker = new google.maps.Marker(props);
    marker.setMap(MAP);
    MAP.setCenter(coord);
    MAP.setZoom(bigZoom);

    google.maps.event.addListener(marker, 'click', function () {
      infoWin.setContent(content);
      infoWin.open(MAP, marker);
    });
  }

  const setMultiMarkers = (mainPinIndex) => {
    const markers = coordinates.map((location, index) => {
      const position = {
        lat: location.lat,
        lng: location.lng
      };
      const content = location.name;
      const deficon = (index === 0) ? mainMarker : defMarker;
      const icon = location.hasOwnProperty('icon') && location.icon !== ''
        ? location.icon
        : deficon;
      const _m = new google.maps.Marker({
        position,
        icon,
        animation: google.maps.Animation.DROP
      });

      _m.addListener('click', function () {
        if (infoWin) infoWin.close();

        infoWin.setContent(content);
        infoWin.open(_m.get('map'), _m);

        MAP.setZoom(8);
        MAP.setCenter(_m.getPosition());
      });

      return _m;
    });
    const marker = new google.maps.Marker();
    const latlngbounds = new google.maps.LatLngBounds();
    const icon = {
      gridSize: 50,
      styles: [
        {
          anchor: [8.5, 0],
          textColor: '#606060',
          textSize: [12],
          url: mainMarker,
          height: 54,
          width: 46
        }
      ],
      maxZoom: 3
    };

    if (coordinates[mainPinIndex]) {
      const centerCoord = {
        lat: coordinates[mainPinIndex].lat,
        lng: coordinates[mainPinIndex].lng
      };

      MAP.setCenter(centerCoord);
      MAP.setZoom(midZoom);
    } else {
      for (let i = 0; i < coordinates.length; i++) {
        latlngbounds.extend(coordinates[i]);
      }

      MAP.setCenter(latlngbounds.getCenter(), MAP.fitBounds(latlngbounds));
    }

    new MarkerClusterer(MAP, markers, icon);

    MAP.addListener('center_changed', function () {
      window.setTimeout(function () {
        MAP.panTo(marker.getPosition());
      }, 6000);
    });

    marker.addListener('click', function () {
      MAP.setZoom(bigZoom);
    });
  }

  const geoLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      return new Promise((resolve, reject) => {
        if (position && position.coords) resolve(position.coords);
        else reject();
      })
        .then(coords => {
          const user = coords.latitude && coords.longitude ?
            {
              lat: coords.latitude,
              lng: coords.longitude
            } : defCenter;

          return user;
        })
        .then(pin => {
          const lat = pin.lat;
          const lng = pin.lng;
          const delta = !Array.isArray(coordinates) ? null : coordinates.map((item, index) => {
            const x = Math.pow((item.lat - lat), 2);
            const y = Math.pow((item.lng - lng), 2);
            const vectr = Math.sqrt(x + y);
            const point = {
              index,
              vectr
            };

            return point;
          });

          delta.sort(function (a, b) {
            if (a.vectr < b.vectr) return -1;

            if (a.vectr > b.vectr) return 1;

            return 0;
          });

          return delta[0].index;
        })
        .then(index => setMultiMarkers(index))
        .catch(() => setMultiMarkers(0));
    });
  }

  if (container instanceof HTMLElement) {
    /** Created map */
    MAP = new google.maps.Map(container, mapProp);

    /** Set marker(s) */
    if (Array.isArray(coordinates) && coordinates.length > 0) {
      /** single */
      if (coordinates.length === 1) setSingleMarker();

      /** multi */
      else {
        try {
          new Promise((resolve, reject) => {
            // if (navigator.geolocation) resolve();
            if (navigator.geolocation) navigator.geolocation.getCurrentPosition(resolve, reject);
            else reject();
          })
            .then(() => geoLocation())
            .catch(() => setMultiMarkers(0));
        } catch (error) {
          console.warn(error);

          setMultiMarkers(0);
        }
      }
    }
  }

}
window.initMap = initMap